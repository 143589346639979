<template>
  <div>
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <div v-else class="w-full flex flex-col gap-8 p-5">
      <div class="flex justify-start items-center gap-6 -ml-3">
        <div class="border-r border-romanSilver h-6 flex">
          <back-button label="Back" @onClick="$router.back()" variant="secondary" />
        </div>
        <h1 class="text-xl text-left font-extrabold">Leave</h1>
        <breadcrumb :items="breadcrumbs" />
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex flex-col justify-center items-start gap-4 my-4">
          <p class="font-semibold text-sm text-darkPurple">
            {{ leavePlan.leaveYear}} Leave Plan for
          </p>
          <div class="w-full flex justify-between items-center">
            <div class="flex justify-start items-start gap-2">
              <div>
                <span v-if="false" class="flex justify-center items-center w-12 h-12">
                  <img src="" class="w-12 h-12 rounded" alt="profile photo" />
                </span>
                <div v-else class="flex justify-center items-center w-12 h-12 rounded border">
                  <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                    {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-1">
                <span class="font-semibold text-darkPurple text-base capitalize">
                  {{ employeeInfo.fname }} {{ employeeInfo.lname }}
                </span>
                <span class="font-normal text-romanSilver text-xs uppercase">
                  {{ employeeInfo.userDesignation && employeeInfo.userDesignation.name }}
                </span>
              </div>
            </div>
            <div class="rounded-md p-2 text-sm w-44 text-center capitalize"
              :class="{
                'bg-green-100 text-mediumSeaGreen': leavePlan.status === 'approved',
                'bg-yellow-100 text-carrotOrange': leavePlan.status === 'pending',
                'bg-red-100 text-flame': leavePlan.status === 'disapproved',
              }"
            >
              {{
                leavePlan.status === 'approved' ? 'Tentatively Approved' :
                leavePlan.status === 'disapproved' ? 'Disapproved' : 'Pending'
              }}
            </div>
          </div>
        </div>

        <div class="h-full w-full my-3 flex flex-col gap-3">
            <c-card class="flex justify-between items-center p-4">
                <div class="flex justify-start items-center gap-2 divide-x divide-romanSilver">
                    <p class="font-bold text-darkPurple text-lg">{{ $DATEFORMAT(new Date(selectedDate), 'MMMM yyyy') }}</p>
                    <p class="text-sm font-normal text-desire pl-2">
                        {{ conflictedEmployees.length > 1 ? (conflictedEmployees.length - 1) : 0 }} Other Employee(s)
                    </p>
                </div>
                <div class="flex justify-start items-center gap-6">
                    <span class="cursor-pointer hover:bg-cultured p-2 rounded" @click="$refs.calendar.previous()">
                        <c-icon icon-name="icon-arrow-left" size="xs" />
                    </span>
                    <span class="cursor-pointer hover:bg-cultured p-2 rounded" @click="$refs.calendar.next()">
                        <c-icon icon-name="icon-arrow-right" size="xs" />
                    </span>
                </div>
            </c-card>
            <calendar
              ref="calendar"
              :time="false"
              hide-title-bar
              hide-view-selector
              :events="conflictedOptions"
              :selected-date="selectedDate"
              events-on-month-view
              :disable-views="['years', 'year', 'week', 'day']"
              :cell-contextmenu="true"
              @view-change="onChangeView($event)"
              class="calendar-class"
          >
              <template #event="{ event }">
                  <template v-if="isEqual(new Date(new Date(event.date).getMonth()), new Date(new Date(selectedDate).getMonth()))">
                      <div class="w-full flex" v-for="(employee, i) in event.conflict" :key="JSON.stringify(employee) + i">
                        <div v-if="employee.id === conflictId" class="text-desire text-xs bg-red-100 border border-desire border-solid rounded-md p-2 w-full">
                          <p class="text-desire text-xs capitalize">{{ employee.fname }} {{ employee.lname }}</p>
                        </div>
                      </div>
                  </template>
              </template>
          </calendar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'date-fns'
import Calendar from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import CIcon from "@/components/Icon"

export default {
components: {
    Calendar,
    BackButton,
    Breadcrumb,
    CCard,
    CIcon,
  },
  data(){
    return {
      isEqual,

      isOpen: false,
      isFetching: false,
      isLoading: false,
      leaveYear: null,
      planId: null,
      conflictId: null,
      selectedDate: new Date(),
      activeConflict: {},
      conflictedLeave: [],
      conflictedOptions: [],
      conflictedEmployees: [],
      leavePlan: {},
      employeeInfo: {},
    }
  },
  watch: {
    activeConflict(value){
      this.conflictedOptions.forEach(item => this.$delete(item, 'active'))
      const findIndex = this.conflictedOptions.findIndex(item => item.date === value.date)
      if (findIndex > -1) this.$set(this.conflictedOptions[findIndex], 'active', true)
    },
  },
  computed: {
    breadcrumbs(){
      return [
        {
          disabled: false,
          text: "Leave Plan",
          href: "Leave Plan",
          id: "Leave Plan"
        },
        {
          disabled: false,
          text: `${this.leavePlan.leaveYear} Leave Plan`,
          href: `${this.leavePlan.leaveYear} Leave Plan`,
          id: `${this.leavePlan.leaveYear} Leave Plan`
        },
        {
          disabled: false,
          text: `View ${this.leavePlan.leaveYear} Leave Plan for ${this.employeeInfo.fname} ${this.employeeInfo.lname}`,
          href: `View ${this.leavePlan.leaveYear} Leave Plan for ${this.employeeInfo.fname} ${this.employeeInfo.lname}`,
          id: `View ${this.leavePlan.leaveYear} Leave Plan for ${this.employeeInfo.fname} ${this.employeeInfo.lname}`
        }
      ]
    }
  },
  methods: {
    onChangeView($event){
      this.selectedDate = $event.startDate
      this.getAdminLeaveCalendar($event.startDate)
    },
    async getAdminLeaveCalendar(selectedDate) {
      const payload = {
        leaveMonth: selectedDate.getMonth() + 1,
        leaveYear: selectedDate.getFullYear()
      }

      await this.$_getAdminLeaveCalendar(payload).then(async ({ data }) => {
        this.conflictedLeave = await data.calendarData.map(item => ({
            ...item, start: item.date, end:  item.date
        }))?.filter(v => v.conflict.length)
      })
      await this.getSingleEmployeeConflict()
    },
    async getAdminEmployeeAnnualLeave(params){
      const payload = { planId: this.planId, params }
      await this.$_getAdminEmployeeAnnualLeave(payload).then(({ data }) => {
        this.leavePlan = data.leavePlan
        this.employeeInfo = data.employee
      })
    },
    getSingleEmployeeConflict(){
      this.conflictedOptions = this.conflictedLeave.filter((item) => (
        item.employees.includes(this.employeeInfo.id)
      ))

      const conflicts = this.conflictedOptions.map(item => item.conflict).flat()
      this.conflictedEmployees = Object.keys(conflicts.reduce((groups, item) => ({
        ...groups, [item.id]: [...(groups[item.id] || []), item]
      }), {}))
    },

    async bootstrapModule(){
      this.isLoading = true
      this.leaveYear = this.$route.params.year
      this.planId = this.$route.params.id
      this.conflictId = this.$route.params.conflictId
      this.selectedDate = new Date(this.$route.params.startDate)

      await this.getAdminEmployeeAnnualLeave()
      await this.getAdminLeaveCalendar(this.selectedDate)
      await this.getSingleEmployeeConflict()
      this.isLoading = false
    },
  },
  created(){
    this.bootstrapModule()
  }

}
</script>

<style scoped>
.calendar-class {
    background: #EEEEEE52;
    border-radius: 8px;
    padding: 24px;
    padding-top: 8px;
    border: 0 !important;
}
::v-deep .vuecal {
    border: 0 !important;
}
::v-deep .vuecal .vuecal__header {
    border: 0 !important;
}
::v-deep .vuecal .weekday-label {
    display: flex;
    justify-content: flex-start;
}
::v-deep .vuecal .weekday-label span {
    font-family: 'Nunito Sans';
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #878E99
}
::v-deep .vuecal .vuecal__cell {
    height: 126px !important;
    border-color: #878E99 !important;
    border-style: solid !important;
    border-width: 0.5px !important;
}
::v-deep .vuecal .vuecal__cell-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px;
}

::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}
</style>